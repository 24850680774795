import React, { useEffect } from "react";
import Layout from "../components/layout";
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import { useLocation } from '@reach/router';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

function LayoutContainer(props) {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // if a hash link, scroll to top, then scroll to id
    if (hash !== '') {
      window.scrollTo(0, 0);
      elementScrollIntoViewPolyfill();
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    }
  }, [pathname, hash]); // do this on route change

  return (
    <PageTransition
      transitionStyles={{
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
      }}
    >
      <ParallaxProvider>
        <Layout {...props} />
      </ParallaxProvider>
    </PageTransition>
  );
}

export default LayoutContainer;
